@import "src/styles/estilosglobais.scss";

.ConsultarModelo {
  display: flex;
  flex-direction: column;

  .Container{
    .ConsultarEquipamentoPesquisar {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 24px;

    }

    .IncluirEditarModelo {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      margin-bottom: 24px;

      .PrimeiraLinha {
        display: grid;
        grid-template-columns: 0.5fr 2fr;
        width: 100%;
        gap: 48px;

        @media (max-width: 800px) {
          display: grid;
          grid-template-columns: 1fr 2fr;
          width: 100%;
          gap: 16px;
        }

      }

      .Linha {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 16px 48px;
        justify-items: flex-start;
        
        @media (max-width: 800px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
          gap: 16px;
          justify-items: flex-start;
        }
      }

      .FiltroSpan {
        font-size: $fonte24;
        color: $cinzaTexto;
        margin-right: 15px;
      }

      .ExportButton{
        display: flex;
        align-items: center;
        gap: 18px; 
        white-space: nowrap
      }
    }
  }

  @media (max-width: 800px) {
    .Container{
      .ConsultarEquipamentoPesquisar{
        display: flex;
        flex-direction: column;
        align-items: start;

        button{
          margin: 0;
          width: 100%!important;
          justify-content: center;
        }
      }
    }
  }
}