@import "src/styles/estilosglobais.scss";

.IncluirEditarCidade {

  .Container {
    .IncluirCidade {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;

      & .firstRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }

      & .secondRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        align-items: start;
      }
      
      & .thirdRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-end;
        gap: 20px;
      }

      @media only screen and (max-width: 1000px) {
        .grid {
          grid-template-columns: 1fr;
          gap: 16px;
        }
      }
    }
  }
}
