@import "src/styles/estilosglobais.scss";

.Dashboard {
  display: flex;
  flex-direction: column;
  max-width: 95vw;

  h3 {
    font-size: $fonte16;
    margin: 16px;
    color: $cinzaTexto 
  }
  
  .Breadcrumb-Btn-Container{

    display: flex;
    justify-content: space-between;

    .Dashboard-BtnList-Container {
      display: flex; 
      justify-content: flex-end;
      gap: 16px;
      align-items: flex-end;
  
      .Dashboard-BtnItem{
        display: flex;
        align-items: center;
        border: $border;
        border-radius: $borderRadius15;
        padding: 8px 16px;
        color: $cinzaTexto;
        cursor: pointer;
        width: 150px!important;
        justify-content: center;
        gap: 8px;
        height: 56px;
        span {
          color: $cinzaTexto;
          opacity: 0.7;
        }
      }
    }
  }

  .Dashboard-body-container{
    border: $border;
    border-radius: $borderRadius15;
    padding: 38px 50px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    align-items: flex-end;

    .NoData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .Barchart-container {
      display: flex;
      flex-direction: column;
      width: 60%;
      border: $border;
      border-radius: $borderRadius15;
      min-height: 500px;
    }

    .Piechart-container {
      display: flex;
      flex-direction: column;
      width: 40%;
      border: $border;
      border-radius: $borderRadius15;
      min-height: 500px;

      .Piechart-subtitle-container {
        display: flex;
        gap: 16px;
        margin: 16px;
        align-items: center;

        .Piechart-subtitle-item {
          display: flex;
          gap: 8px;
          align-items: center;

          span{
            color: $cinzaTexto
          }
        }
      }

      
    }

    .Dashboard-tempo-medio {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: $border;
      border-radius: $borderRadius15;
      min-height: 500px;
    }
    .Dashboard-footer-vertical-bar-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      border: $border;
      border-radius: $borderRadius15;
      height: fit-content;
      min-height: 500px;

      .Dashboard-vertical-bar-container {
        display: flex;
        min-height: fit-content;
        
        .Dashboard-vertical-bar-done{
          background: $primary; 
          height: 59px; 
          border-radius: 15px 0 0 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $secondary;
          font-size: $fonte16;
        }
  
        .Dashboard-vertical-bar-total {
          background: $cinzaTexto;
          height: 59px;
          border-radius: 0 15px 15px 0
        }

      }

    }
    
  }

}

.Row {
  display: flex;
  gap: 16px;
  width: 100%;
}

.css-109ca9x-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  min-width: none!important;
  width: 50px!important;
}

@media (max-width: 800px) {
  .Dashboard-body-container{
    padding: 0!important;
    border: none!important;
    .Row{
      display: flex;
      flex-direction: column;
      .Barchart-container {
        display: flex;
        flex-direction: column;
        width: 100%!important;
        border: $border;
        border-radius: $borderRadius15;
        min-height: 500px;
      }
      .Piechart-container{
        display: flex;
        flex-direction: column;
        width: 100%!important;
        border: $border;
        border-radius: $borderRadius15;
        min-height: 500px;
      }
    }
  }
}