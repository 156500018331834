@import "src/styles/estilosglobais.scss";

#ConsultarOS {
  .MuiFormControl-root {
    margin-top: 0 !important;
  }

  .InputComponentDate, .MuiInputBase-root {
    background: #fff;
  }

  #TabelaResultadoConsultaFamilia {
    @media only screen and (max-width: 1000px) {
      span {
        font-size: 0.775rem;
      }
    }
  }
}
