@import "src/styles/estilosglobais.scss";

.IncluirEditarModelo{
  .Container{
    .IncluirModelo{
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 24px;
    }
  }
  .ButtonSalvarContainer{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  @media (max-width: 800px) {
    .Container{
      .IncluirModelo{
        display: flex;
        flex-direction: column;
      }
    }
    .ButtonSalvarContainer{
      display: flex;
      justify-content: flex-end;
      width: 100%;
      
      & > button {
        width: 100%;

      }
    }
    
  }
}