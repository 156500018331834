#list-notifications {
    max-width: 300px;
}

#list-notifications ul li.notification-item {
  margin: 5px 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 15px;
}

#list-notifications ul li.notification-item:last-child {
    border-bottom: none;
}

#list-notifications ul li.notification-item > div h3 {
  text-align: center;
  display: flex;
  flex: 92% 1;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

#list-notifications ul li.notification-item > div {
  display: flex;
}

#list-notifications ul li.notification-item > div .icon-notification {
  flex: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

#list-notifications ul li.notification-item > div svg {
  margin: auto;
}

#list-notifications ul li.notification-item > div .content-notification .buttons-form{
  text-align: right;
}

#list-notifications ul li.notification-item > div .content-notification button{
  border-radius: 5px !important;
  height: 45px !important;
  width: 100%;
}

#list-notifications ul li.notification-item > div .content-notification fieldset#root {
  border: none;
}

#list-notifications ul li.notification-item > div .content-notification fieldset#root label.control-label{
  width: 100%;
  font-weight: bold;
  margin: 5px auto;
  display: block;
}

#list-notifications ul li.notification-item > div .content-notification fieldset#root input.form-control{
  border: 1px solid #afb1b1;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1rem;
  height: 45px;
  padding: 4px 10px;
  width: 100%;
  margin-bottom: 10px;
}

#list-notifications ul li.notification-item > div .content-notification fieldset#root p.field-description{
  font-size: small;
  margin: 10px auto;
  text-align: justify;
}

#list-notifications ul li.notification-item > div .content-notification fieldset#root p.field-description{
  font-size: small;
  margin: 10px auto;
  text-align: justify;
}

#list-notifications ul li.notification-item > div .content-notification .notification-item-date{
  font-size: smaller;
  margin: 10px auto;
  text-align: right;
}



