@import "src/styles/estilosglobais.scss";

.ConsultaPrestador {
  display: flex;
  flex-direction: column;

  .Container{
    .FiltroSpan {
      display: block;
      font-size: $fonte24;
      color: $cinzaTexto;
    }
    .ConsultarPrestadorPesquisar {
      display: flex;
      gap: 10px;
      align-items: flex-end;

      .ConsultarPrestadorSelectStatus{
        width: 35%;
      }

      .ExportButton{
        display: flex;
        align-items: center;
        gap: 18px; 
        white-space: nowrap
      }

    }
  }

  @media (max-width: 800px) {
    .Container{
      .ConsultarPrestadorPesquisar{
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-bottom: 0;

        & > div {
          width: 100%!important;
        }

        .ConsultarEquipamentoSelectStatus{
          width: 100%;
        }

        .ConsultarEquipamentoSelectStatus{
          width: 100%;
        }

        button{
          margin: 0;
          width: 100%!important;
          justify-content: center;
        }
      }
    }
  }
}