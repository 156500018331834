@import "src/styles/estilosglobais.scss";

.RotasOtimizadasContainer {
    display: flex;
    flex-direction: column;
    gap: 0 !important;

    .FiltroSpan {
        display: block;
        font-size: $fonte24;
        color: $cinzaTexto;
    }

    .grid1 {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr;
        gap: 16px;
        margin-bottom: 16px;
    }

    .grid2 {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr 3fr;
        gap: 16px;
        margin-bottom: 16px;
        align-items: flex-start;
    }

    .bt {
        margin-top: 32px;
    }

    .center {
        text-align: center;
    }

    .container-filtro {
        display: flex;
        justify-content: space-between;
    }

    .Span {
        display: block;
        font-size: $fonte24;
        color: $cinzaTexto;
        margin: 16px 0;
    }


}

.modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
    padding: 32px;
    box-sizing: border-box;

    & .MuiAlert-action {
        margin: unset;
    }
}