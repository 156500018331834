@import "src/styles/estilosglobais.scss";

.Mui-readOnly{
  background: $readOnlyBg;
  color: $cinza!important;
}

.label {
  font-size: $fonte20;
  margin-bottom: 16px;
  color: $cinzaTexto;
  &.sm {
    font-size: $fonte12!important;
    font-weight: 600;
    height: 1rem!important;
  }
}

.Seletor-Placeholder {
  color: $cinzaTexto;
}

.select {
  border-radius: 15px!important;
  font-size: $fonte14;
  height: 56px;
  background-color: white;

  &.sm {
    height: 2.75rem;
    font-size: $fonte14;
  }
}

.errorMessage {
  color: $vermelho;
  font-size: $fonte12;
  font-weight: 600;
}