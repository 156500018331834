.consultarAgrupamentoModelo-Container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filtro-Modelo {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1; 
}

.botao-PesquisarModelo-container {
  display: flex;
  gap: 10px;
}

.tabelaModelo {
  padding-top: 40px;
}

.modalErro-Modelo {
  padding-top: 40px;
}