@import "src/styles/estilosglobais.scss";

.ChangePasswordHeader {
  width: 100%;
  height: 64px;
  background-color: $primary;
  color: $secondary;
  font-size: 34px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .Ambiente {
    position: absolute;
    right: 30px;
    top: 15px;
    font-size: 25px;
    color: $secondary;
  }
}
