@import "src/styles/estilosglobais.scss";

.Header-Topo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Header-Topo-left {
    display: flex;
    align-items: center;
    gap: 1rem;

    @media only screen and (max-width: 420px) {
      align-items: center;
    }

    h1 {
      line-height: 3rem;
    }
    .Burguer-Menu-Icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      line {
        stroke: $cinza;
      }
    }
    .Header-Typography {
      font-size: 1.8rem;

      @media only screen and (max-width: 630px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      @media only screen and (max-width: 420px) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  .HeaderTitle {
    font-family: $fonteFamilyTitulo;
    font-weight: $extrabold;
    font-size: $fonte32;
    color: $cinzaTexto;
    letter-spacing: 1.2px;
  }

  @media (max-width: 800px) {
    h1{
      line-height: 1rem!important;
      font-size: 16px;
    }
  }
}

hr {
  margin: 16px 0!important;
}