#termoOS-page {
  .termoOS-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filtro {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
  }
  
  .botao-container {
    display: flex;
    gap: 10px;
  }
  
  .tabelaTermo {
    padding-top: 40px;
  }
  
  .modalErro {
    padding-top: 40px;
  }
}
