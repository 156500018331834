@import "src/styles/estilosglobais.scss";

input[readonly]{
  background: $readOnlyBg;
  color: $cinza;
}

textarea[readonly]{
  background: $readOnlyBg;
  color: $cinza;
}

.InputComponent {
  border: 0;
  outline: 0;
  margin: 0;
  font-size: $fonte16;
  box-sizing: border-box;
  border: $border;
  border-radius: 15px;
  height: 56px;
  padding: 16.5px 14px;

  &.sm {
    height: 44px;
    font-size: $fonte14;

    &::placeholder {
      font-size: $fonte14;
    }
  }
  
  &.xs {
    height: 32px;
    font-size: $fonte14;
    padding: 5px 10px;

    &::placeholder {
      font-size: $fonte14;
    }
  }

  &::placeholder {
    color: $cinzaClaro;
    font-size: $fonte14;
  }

  &:hover {
    border: 1px solid $primary;
  }
  
  &:disabled:hover {
    border: $border;
  }

  &:focus {
    border: 2px solid $primary;
  }

  &.error {
    border: 1px solid $vermelho;
  }

  .errorMessage {
    color: $vermelho;
    font-size: $fonte12;
    font-weight: 600;
  }
}

.TextAreaComponent {
  border: 0;
  outline: 0;
  margin: 0;
  font-size: $fonte16;
  box-sizing: border-box;
  border: $border;
  border-radius: 15px;
  padding: 16.5px 14px;

  &.sm {
    font-size: $fonte14;
    height: 64px;

    &::placeholder {
      font-size: $fonte14;
    }
  }

  &.sm {
    font-size: $fonte14;
    height: 64px;

    &.rowsTextArea {
      height: unset;
    }
  }

  &

  &::placeholder {
    color: $cinzaClaro;
    font-size: $fonte16;
  }

  &:hover {
    border: 1px solid $primary;
  }

  &:focus {
    border: 2px solid $primary;
  }

  &.error {
    border: 1px solid $vermelho;
  }

  .errorMessage {
    color: $vermelho;
    font-size: $fonte12;
    font-weight: 600;
  }
}

.label-input, .textarea-label {
  font-size: $fonte20;
  margin-bottom: 16px;
  color: $cinzaTexto;
  &.sm {
    font-size: $fonte12;
    font-weight: 600;
    height: unset;
  }
  &.xs {
    font-size: $fonte12;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.container {
  margin: 0!important;
}