@import "src/styles/estilosglobais.scss";

.containerRoterizador {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .NormalGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 48px;
        margin-bottom: 32px;

        .MuiFormGroup-row {
            gap: 39px;

            span {
                color: $cinzaTexto;
            }
        }

        .seletor-botao{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            
            .icon{
                margin-bottom: 4px;
            }
        }
    }

    .container-botao {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 32px;
    }

    .container-collapse {
        padding: 16px;
    }
}