@import "src/styles/estilosglobais.scss";

.DetalhesOSContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  @media only screen and (max-width: 900px) {
    .CardsContainer,
    .Row,
    .grid1,
    .gridEnderecov2,
    .gridEndereco {
      grid-template-columns: 1fr !important;
      gap: 16px;
    }

    .acoesBotoesFlutuantes {
      justify-content: center !important;
    }
  }

  @media only screen and (min-width: 900px) and (max-width: 1120px) {
    .gridEnderecov2, .gridEndereco {
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 16px;
    }
  }

  .botaoVoltar {
    cursor: pointer;
    margin-left: auto;
  }

  .flexTopoAcoes {
    display: grid;
    grid-template-columns: 1fr 50px;
  }
  .acoesBotoesFlutuantes {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: end;
    gap: 20px;
    background-color: #fff;
    border: 1px solid #afb1b1;
    border-radius: 15px;
    padding: 5px 30px;
  }

  .errorField {
    border: 1px solid red !important;
  }

  .SubHeaderContainer {
    display: flex;

    .IconsContainer {
      display: flex;
      gap: 16px;
      align-items: flex-end;

      .IconContainer {
        display: flex;
        padding: 16px;
        border-radius: 15px;
        border: $border;
        max-width: 57px;
        max-height: 64px;
        cursor: pointer;
      }

      .IconEnabled {
        background-color: $primary;
        svg {
          path {
            fill: white;
          }
        }
      }
    }

    .MudarStatusContainer {
      display: grid;
      grid-template-columns: 1fr 44px;
      align-items: end;
      margin-left: auto;
      width: 400px;
      gap: 10px;

      & #atualizarStatus {
        margin: 0;
        width: 44px;
        height: 44px;
        padding: 4px 2px 2px 2px;

        .refreshIcon {
          height: 20px;
        }
      }
    }
  }

  textarea.TextAreaComponent.ObservacaoField {
    height: 80px;
    padding: 10px;
  }

  .AccordionContainer {
    display: flex;
    border: $border;
    border-radius: $borderRadius15;
    flex-direction: column;
    color: $cinzaTexto;

    & > .open {
      margin-top: 16px;
    }

    & .MuiCollapse-wrapper {
      padding: 10px 30px 10px 30px;
    }

    & > .AccordionHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px 10px 30px;
      cursor: pointer;

      & > svg {
        width: 18px;
        height: 18px;
        margin: 0;
      }

      & > p {
        color: $cinzaTexto;
        font-size: $fonte16;
        font-weight: 600;
      }
    }
  }

  .DetalheOS {
    display: flex;
    padding: 10px 24px 20px 24px;
    background: $cinzaMtoClaro;
    border-radius: $borderRadius15;
    flex-direction: column;
    gap: 16px;
    color: $cinzaTexto;
    margin-top: 16px;

    .title {
      font-size: 14px;
      font-weight: $bold;
    }

    textarea {
      resize: none;
    }

    .CardsContainer {
      width: 100%;
      gap: 26px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .Card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        background: $branco;
        border: $border;
        border-bottom: 8px solid $primary;
        border-radius: 20px;
        overflow: hidden;
        padding: 16px;
      }
    }

    .Row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .RowItem {
        display: flex;
        flex-direction: column;

        & > span {
          font-size: $fonte12;
        }
      }
    }

    .ButtonContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 100%;
    }

    .PerimeterDiv {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #797d8c;
    }

    .IncluirInfoButton {
      width: 267px;
    }

    .gridEnderecov2 {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 15px;

      & .gridItem {
        gap: 5px;
      }
    }

    .gridEnderecov2:not(:first-of-type) {
      margin-top: 20px;
    }

    .gridEndereco {
      display: grid;
      grid-template-columns: 50px repeat(5, 1fr);

      & .gridItem {
        gap: 16px;

        & .smallIcon {
          width: 40px;
          height: 40px; /* Ajuste conforme necessário */
          cursor: pointer;
        }
      }
    }

    .gridChips {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      & .gridItem {
        gap: 16px;

        & .smallIcon {
          width: 40px;
          height: 40px; /* Ajuste conforme necessário */
        }
      }
    }

    .gridEndereco:not(:first-of-type) {
      margin-top: 24px;
    }

    .gridRastreamento {
      @extend .gridEndereco;
      align-items: center;

      & > .gridItem {
        justify-content: center;
        align-items: center;
      }
    }

    .AtendimentoRealizadoInsucesso {
      .Insucesso {
        display: flex;
        padding: 16px;
        margin-top: 16px;
        border: $border;
        border-radius: $borderRadius15;
        flex-direction: column;
        gap: 16px;
        color: $cinzaTexto;
        margin-top: 16px;

        .firstGrid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
        }

        .secondGrid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .gridAtendimentosRealizados {
      @extend .gridEndereco;

      .linha {
        display: grid;
        width: 100%;
        grid-template-columns: 1.2fr 0.8fr;
        gap: 16px;

        .gridItemLeft {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          column-gap: 15px;
          row-gap: 50px;
        }

        & > .gridItem {
          display: flex;

          .tomtomAttribution {
            display: none;
          }

          & > .row2items {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
          }

          .column {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }

  .DadosCliente,
  .DadosProduto,
  .Periodos,
  .DadosAtendimento {
    & > p.font14 {
      font-weight: $bold;
      margin-bottom: 8px;
    }

    .grid1 {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 30px 0px;
    }
  }

  .DadosCliente {
    .grid1 {
      grid-template-columns: 1fr 0.5fr 1fr 0.5fr 1fr;
      padding: 10px 20px 10px 20px;
      .gridItem {
        gap: 5px;
      }
    }
    .font12.thin {
      width: 100%;
      min-height: 24px;
      border-radius: 15px;
      border: 1px solid #afb1b1;
      display: flex;
      padding: 5px 10px;
      background-color: #fff;
      height: 32px;
      align-items: center;
    }
  }
}

.gridItem {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 8px 20px;

  & > span {
    font-size: $fonte12;
  }

  .thin {
    font-weight: 400;
  }
}

.font14 {
  font-size: $fonte14;
}

.font12 {
  font-size: $fonte12;
}

.font32 {
  font-size: $fonte32;
}

.font22 {
  font-size: $fonte22;
}

.open {
  margin-top: 16px;
}

.map-container {
  max-height: 165px;
  min-height: 165px;
  min-width: 165px;
  width: 100%;

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 1px solid #afb1b1;
  }
}

.custom-marker-detalhesOS {
  background-image: url("../../../assets/icons/MapLocal.svg");
  background-size: cover;
  width: 35px;
  height: 45px;
}

.Periodos {
  .grid-datas {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .item-data {
      flex: 1;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;

        &.thin {
          font-weight: 300;
        }
      }

      input {
        width: 100%;
      }
    }
  }
}
.grid-chatbot {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  .grid-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    align-items: center;

    .grid-item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      label {
        font-size: 12px;
        color: #666;
      }
    }
  }
}

