@import "src/styles/estilosglobais.scss";

.TableContainerTipoEquipamento{
  width: 100%;
}

.TabsIncluirEditarModelo {
  width: 100%;

  button{
    min-height: 44px;
    color: $cinzaTexto;
    border: 1px solid $cinzaClaro;
    border-radius: 15px 15px 0 0 ;
  }
  button[aria-selected="true"]{
    border-bottom: 0px;
  }

  .FillingButton{
    border-style: none none solid none!important;
  }

  .MuiTabs-indicator{
    display: none;
  }
}

.TabPanel{
  width: 100%;
}
