@import "src/styles/estilosglobais.scss";

.ContainerEditarPerfil, .ContainerEditarTela {
  padding: 36px 0 0 16px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .MuiAutocomplete-inputRoot {
    padding: 3px !important;
  }

  .AdicionarTela{
    width: 40%;
    display: flex;
    align-items: flex-end;
    gap: 14px;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

  }

  .TableContainerPage {
    width: 40%;
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}