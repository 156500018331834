@import "src/styles/estilosglobais.scss";

.IncluirEditarEstado 
{
    .Container {
        .IncluirEstado {
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: flex-end;
    
          & .firstRow {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(1, 1fr 1fr 1fr 2fr);
            gap: 20px;
          }
    
          & .secondRow {
            width: 100%;
            display: flex;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            align-items: start;
            
          }
          
          & .thirdRow {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: flex-end;
            gap: 20px;
          }
    
          @media only screen and (max-width: 1000px) {
            .grid {
              grid-template-columns: 1fr;
              gap: 16px;
            }
          }
        }
      }
}