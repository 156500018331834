@import "src/styles/estilosglobais.scss";

.ValidarEmail {
  display: flex;
  flex-direction: column;

  .ValidarEmailBox {
    margin: auto;
    padding: 50px 158px;
    box-sizing: border-box;
    width: 784px;

    .ValidarEmailBoxWrapper {
      width: 100%;
    }

    .ValidarEmailBox-texto {
      font-family: $fonteFamilyTitulo;
      font-style: normal;
      font-weight: $bold;
      font-size: $fonte36;
      margin-top: 18px;
      margin-right: 1px;
      text-align: start;
      color: $primary;
    }

    .validar-email-form-group-texto {
      font-size: $fonte14;
      line-height: 16px;
      color: #797d8c;
      margin: 15px 0;
    }
  }
}

@media (max-width: 800px) {
  .ValidarEmail {
    max-width: 100vw;
    .ValidarEmailBox {
      padding: 24px 12px;
      max-width: 100vw;
    }
  }
}
