@import "src/styles/estilosglobais.scss";

.FarolOS-PopoverFiltro-box {
  width: 610px;
  background: $branco;
  border: $border;
  border-radius: $borderRadius15 !important;

  .FarolOS-PopoverFiltro {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 18px 28px 28px 28px;
    gap: 16px;

    .FarolOS-PopoverFiltro-options{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 16px;
    }

    .FarolOS-PopoverFiltro-label {
      font-size: $fonte12;
      color: $cinzaTexto;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      border-radius: $borderRadius15;
    }

    .FarolOS-PopoverFiltro-Seletor {
      font-size: $fonte12;
      color: $cinzaTexto;

      .Seletor-Placeholder {
        font-size: $fonte12;
        color: $cinzaTexto;
      }
    }

    .FarolOS-PopoverFiltro-BotaoFiltrar {
      height: 53px;
      background-color: $primary;
      border-radius: $borderRadius15;
      font-size: $fonte14;
      font-weight: $regular;

      &:disabled {
        background-color: $cinzaClaro;
        color: $branco;
      }
    }

    .FarolOS-PopoverFiltro-BotaoLimpar {
        height: 53px;
        border-radius: $borderRadius15;
        font-size: $fonte14;
        font-weight: $regular;
        border: 1px solid $primary;
      }
  }
}

.Seletor-Option {
  font-size: $fonte12;
  color: $cinzaTexto;
}

.Seletor-OptionGroup {
  font-size: $fonte12;
  color: $pretoTexto;
}
