@import "src/styles/estilosglobais.scss";

.Mui-disabled {
  background: $readOnlyBg;
  color: $cinza;
}

.InputComponentDate {
  .MuiInputBase-root.MuiInputBase-formControl {
    border-radius: 15px;
  }

  &.sm {
    font-size: $fonte14;

    &::placeholder {
      font-size: $fonte14;
    }

    .MuiInputBase-root.MuiInputBase-formControl {
      height: 44px;
    }
  }

  &::placeholder {
    color: $cinzaClaro;
    font-size: $fonte16;
  }

  &.error {
    .MuiInputBase-root.MuiInputBase-formControl {
      border: 1px solid $vermelho;
    }

    .MuiOutlinedInput-notchedOutline {
      border: unset;
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $vermelho;
      }
    }
  }

  .errorMessage {
    color: $vermelho;
    font-size: $fonte12;
    font-weight: 600;
  }
}



.container {
  margin: 0 !important;

  .label-input,
  .textarea-label {
    font-size: $fonte20;
    margin-bottom: 16px;
    color: $cinzaTexto;

    &.sm {
      font-size: $fonte12;
      font-weight: 600;
    }
  }
}