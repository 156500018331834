@import "src/styles/estilosglobais.scss";

.IncluirEditarGrupoServico{
  .Container{
    .IncluirGrupoServicoGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      gap: 10px;
      align-items: start;
      margin-bottom: 24px;
    }
    .IncluirGrupoServico {
      display: flex;
      gap: 10px;
      align-items: start;
      margin-bottom: 24px;
    }
    .ButtonSalvarContainer {
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }
  }

  @media (max-width: 800px) {
    .Container{
      .IncluirGrupoServicoGrid {
        grid-template-columns: 1fr;
      }
      .IncluirGrupoServico {
        display: flex;
        flex-direction: column;
      }
    }
    button {
      width: 100%;
    }
  }
}