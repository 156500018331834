@import "src/styles/estilosglobais.scss";

.noOptionsWrapperClass {
  color: $cinzaTexto !important;
}

.Mui-disabled {
  background: $readOnlyBg;
  color: $cinza;
}

.noOptionsWrapperClass {
  color: $cinzaTexto !important;
}

.MuiAutocomplete-noOptions {
  font-size: $fonte14;
  color: $cinzaTexto;
}

.MuiAutocomplete-option {
  font-size: $fonte14;
}

.Autocomplete {
  display: flex;
  flex-direction: column;

  & .MuiOutlinedInput-root {
    padding-right: 30px !important;
  }

  input {
    font-size: $fonte14;

    ::placeholder {
      font-size: $fonte12;
    }
  }

  .label {
    font-size: $fonte20;
    margin-bottom: 16px;
    color: $cinzaTexto;

    &.sm {
      font-size: $fonte12 !important;
      font-weight: 600;
    }
    &.xs {
      font-size: $fonte12 !important;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

.errorMessage {
  color: $vermelho;
  font-size: $fonte12;
  font-weight: 600;
}
