@import "src/styles/estilosglobais.scss";


.Checkboxclass.CheckboxCardOSList {
  margin-left: unset;
  margin-right: unset;

  input.Checkboxinputclass {
    width: 25px;
    height: 25px;
  }

  input.Checkboxinputclass:checked::before {
    font-size: 20px;
    right: 3px;
  }
}

.FarolOS-CardOS-StatusIcon {
  margin: auto;

  &.OSEmergencial {
    circle {
      fill: $osEmergencial;
    }
  }
}

.OSPendente-InfoIcon {
  transform: scale(1.1);
  cursor: pointer;

  path {
    fill: #AFB1B1;
  }
}

.OSPendente-Icon {
  transform: scale(0.8);
  cursor: pointer;

  path {
    fill: #AFB1B1;
  }
}

.CardOS-ExpandIcon {
  margin: auto;
  cursor: pointer;
  transition: transform 0.3s, padding 0.6s;

  &.cardOpen {
    transform: rotate(180deg);
  }
}

.AlignCenter{
  display: flex;
  text-align: center
}
