@import "src/styles/estilosglobais.scss";

.noOptionsWrapperClass {
  color: $cinzaTexto !important;
}

.Autocomplete {
  display: flex;
  flex-direction: column;

  .sm {
    height: 2.75rem;
    font-size: $fonte14;
  }

  #Autocompletetecnico {
    font-size: $fonte14;
  }


  .label {
    font-size: $fonte20;
    margin-bottom: 16px;
    color: $cinzaTexto;
    &.sm {
      font-size: $fonte12!important;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
}

.Input-tecnico {
  background-color: white;
}
