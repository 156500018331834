@import "src/styles/estilosglobais.scss";

.GeralTabContainer, .ConfiguracaoTabContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;

  .MuiFormControl-root{
    justify-content: flex-end;
  }

  .Row {
    display: flex;
    gap: 16px;
    align-items: flex-end;
  }

  .NormalGrid, .CheckboxGrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 48px;

    .Checkboxclass{
      justify-content: flex-start;
      margin: 8px 0;
    }
    
    .MuiFormGroup-row {
      gap: 39px;
      span{
        color: $cinzaTexto;
      }
    }
  }

  .EnderecoGrid{
    display: grid;
    grid-template-columns: 0.68fr 0.35fr 1fr;
    gap: 16px 48px;
  }
}

@media (max-width: 800px) {
  .GeralTabContainer,.ConfiguracaoTabContainer{
    .NormalGrid{
      grid-template-columns: 1fr;
      gap: 16px 24px;
    }

    .CheckboxGrid{
      grid-template-columns: repeat(2, 1fr);
      .Checkboxclass{
        align-items: center;
        display: grid;
        grid-template-columns: 20px 1fr;
      }
    }
    .EnderecoGrid{
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px 24px;
    }
  }
}