@import "src/styles/estilosglobais.scss";

.ConsultarPerfil {
  display: flex;
  flex-direction: column;

  .Container{
    .ConsultarPerfilPesquisar, .IncluirPerfil{
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 24px;

      .FiltroSpan {
        font-size: $fonte22;
        color: $cinzaTexto;
        margin-right: 15px;
      }

      .ConsultarPerfilSelectStatus, .ConsultarPerfilSelectPerfil  {
        max-width: 25%;
      }

      .IncluirPerfilSelectStatus{
        min-width: 25vw;
        width: 25vw;
      }
    }

    .IncluirPerfil{
      gap: 64px;

      .InputContainer {
        min-width: 25vw;
        width: 25vw;

        @media only screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }

    .TabsIncluirEditarPerfil {

      button{
        min-height: 44px;
        color: $cinzaTexto;
        border: 1px solid $cinzaClaro;
        border-radius: 15px 15px 0 0 ;
        
        @media only screen and (max-width: 1000px) {
          font-size: $fonte12;
        }
      }
      button[aria-selected="true"]{
        border-bottom: 0px;
      }

      .FillingButton{
        border-style: none none solid none!important;
      }

      .MuiTabs-indicator{
        display: none;
      }
    }

    .ButtonSalvarContainer{
      display: flex;
      justify-content: flex-end;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        
        & > button {
          width: 100%;

        }
      }
    }
  }
}

@media (max-width: 800px) {
  .Container{

    .IncluirPerfil{
      gap: 10px!important;

      .IncluirPerfilSelectStatus{
        width: 100%!important;
      }
    }
    .ConsultarPerfilPesquisar, .IncluirPerfil{
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: flex-start!important;

      .ConsultarPerfilSelectPerfil, .ConsultarPerfilSelectStatus{
        width: 100%;
        max-width: 100%!important
      }

      button{
        width: 100%;;
        margin: 0;
      }
    }
  }
}