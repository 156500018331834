/*  Importar esse arquivo no estilo da tela:
*   @import "src/styles/estilosglobais.scss";
*/

//Cores layout
$primary: #294033;
$primaryLight: #535e58;
$primaryDark: #1e2e25;
$primaryLighter: #4FD1A3;
$secondary: #ffffff;
$bgCinzaClaro: #F9F9F9;

$osEmergencial: #FF9900;
$osVencida: #FF2323;
$osVenceHoje: #FFE600;
$osAVencer: #97A800;

$cinza: #797d8c;
$cinzaClaro: #afb1b1;
$cinzaMtoClaro: #F8F8F8;
$branco: #ffffff;
$preto: #000000;

$azul90: rgba(30, 91, 182, 0.9);
$azul80: rgba(30, 91, 182, 0.8);
$azul70: rgba(30, 91, 182, 0.7);
$azul60: rgba(30, 91, 182, 0.6);
$azul50: rgba(30, 91, 182, 0.5);
$azul40: rgba(30, 91, 182, 0.4);
$azul30: rgba(30, 91, 182, 0.3);
$azul20: rgba(30, 91, 182, 0.2);
$azul10: rgba(30, 91, 182, 0.1);

$cinza50: rgba(121, 125, 140, 0.5);
$cinza10: rgba(121, 125, 140, 0.1);
$cinza05: rgba(121, 125, 140, 0.05);

$vermelho: #ff2323;
$amarelo: #ffe600;
$verde: #97a800;

$vermelho34: rgba(255, 35, 35, 0.34);
$amarelo34: rgba(255, 230, 0, 0.34);
$verde34: rgba(151, 168, 0, 0.34);

//Fontes
$fonteFamilyTitulo: "Noto Sans";
$fonteFamilyTexto: "Open Sans";

// TAMANHO DAS FONTES ALTERADO PARA REM PARA MELHORAR ACESSIBILIDADE

$fonte12: 0.75rem;
$fonte13: 0.813rem;
$fonte14: 0.875rem;
$fonte15: 0.938rem;
$fonte16: 1rem;
$fonte17: 1.063rem;
$fonte18: 1.125rem;
$fonte20: 1.25rem;
$fonte22: 1.375rem;
$fonte24: 1.5rem;
$fonte26: 1.625rem;
$fonte32: 2rem; //Ex: Titulo Dashboard
$fonte36: 2.25rem;
$fonte55: 3.438rem;

$cinzaTexto: #797d8c;
$pretoTexto: #000000;

$regular: 400;
$semibold: 500;
$bold: 600;
$extrabold: 700;

//Elementos
$readOnlyBg: #F5F5F5;
$borderRadius: 5px;
$borderRadius15: 15px;
$border: 1px solid #afb1b1;
