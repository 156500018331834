@import "src/styles/estilosglobais.scss";

#EditarFamilia {
  .EditarFamilia-Container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px 63px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 63px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px 63px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .MuiInputBase-root.MuiInputBase-formControl {
    font-size: 1rem !important;
    margin-bottom: 10px !important;
    border-radius: 15px !important;
    height: 44px !important;

    &.error {
      border: 1px solid $vermelho;
    }
  }

  .button-list-right {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 18px;

    @media only screen and (max-width: 1000px) {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
}
