@import "src/styles/estilosglobais.scss";

.FarolOS-PopoverOrdenar-box {
  width: 323px;
  background: $branco;
  border: $border;
  border-radius: $borderRadius15 !important;

  .FarolOS-PopoverOrdenar {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 18px 28px 28px 28px;

    .FarolOS-PopoverOrdenar-label {
      font-size: $fonte12;
      color: $cinzaTexto;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      border-radius: $borderRadius15;
    }

    .FarolOS-PopoverOrdenar-Seletor {
      font-size: $fonte12;
      color: $cinzaTexto;

      .Seletor-Placeholder {
        font-size: $fonte12;
        color: $cinzaTexto;
      }
    }

    .FarolOS-PopoverOrdenar-BotaoOrdenar {
      margin-top: 16px;
      height: 53px;
      background-color: $primary;
      border-radius: $borderRadius15;
      font-size: $fonte14;
      font-weight: $regular;
      color: $secondary;
    }
  }
}

.SeletorOrdenar-Option {
  font-size: $fonte12;
  color: $cinzaTexto;
  padding: 10px 0;
}
