@import "src/styles/estilosglobais.scss";

.IncluirEditarMensagemInsucesso {
  .Container {
    .IncluirMensagemInsucesso {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;
      border-radius: 15px;
      background-color: $cinzaMtoClaro;
      padding: 40px;

      .Checkboxclass {
        font-weight: 600;
      }

      & .firstRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }

      & .secondRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        align-items: center;
      }

      & .thirdRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: flex-end;
        gap: 20px;
      }
      
      & .gridBotoes {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 180px 180px;
        gap: 20px;
      }

      @media only screen and (max-width: 1000px) {
        .grid {
          grid-template-columns: 1fr;
          gap: 16px;
        }
      }
    }
  }
}
