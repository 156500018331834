@import "src/styles/estilosglobais.scss";

#EditarTermo {
  /**Remove setas de input number*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .EditarTermo-Container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .InsideGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid4 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  .button-list-right {
    display: flex;
    gap: 8px;
    justify-content: flex-end;

    @media only screen and (max-width: 1000px) {
      button {
        width: 100%;
      }
    }
  }
}
.responsive-textarea {
  width: 100%;
  min-width: 300px;
  max-width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  resize: vertical;
  overflow-y: auto;

  @media (max-width: 1200px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
}
