@import "src/styles/estilosglobais.scss";

.SendToTechnician {
  .flexTopoAcoes {
    display: grid;
    grid-template-columns: 1fr 50px;

    .botaoVoltar {
      cursor: pointer;
      margin-left: auto;
    }
  }

   .flexBottomAcoes {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 180px;
  }

  .grid1 {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 180px;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
  }

  .checkbox-container {
    min-width: 110px;
    display: flex;
    justify-content: center;
  }

  .SendToTechnician-Topo {
    display: flex;
    justify-content: space-between;
  }

  .editable-cell {
    display: flex;
    gap: 8px;
  }

  .table-input {
    width: 110px;
    height: 32px !important;
  }

  /**Remove setas de input number*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-width: 900px) {
    .grid1 {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }
  }
}
