@import "src/styles/estilosglobais.scss";

.Container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Status {
    width: 20%;
  }

  .InputPrestador{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .TabsIncluirEditarPrestador {

    & > div {
      & > div {
        overflow: auto;
      }
    }

    & > div:last-child{
      width: 100%;
    }

    button{
      min-height: 44px;
      color: $cinzaTexto;
      border: 1px solid $cinzaClaro;
      border-radius: 15px 15px 0 0 ;
    }
    button[aria-selected="true"]{
      border-bottom: 0px;
    }

    .FillingButton{
      border-style: none none solid none!important;
    }

    .MuiTabs-indicator{
      display: none;
    }
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;
  }

}

@media (max-width: 800px) {

  .TabsIncluirEditarPrestador {

    & > div {
      & > div {
        overflow: auto;
      }
    }

  }

  .ButtonContainer{
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      width: 100%!important;
    }
  }

  .MuiTab-fullWidth{
    min-width: fit-content!important;
  }
  .GeralTabContainer
  .NormalGrid
  .MuiFormGroup-row,
  .ConfiguracaoTabContainer
  .NormalGrid
  .MuiFormGroup-row {
    gap: 0!important;
  }

  .Status{
    width: 100%!important;
  }
}