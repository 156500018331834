@import "src/styles/estilosglobais.scss";

.flexCheckbox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Checkboxclass {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.error {
    #checkboxinput {
      border: 1px solid $vermelho;
    }
  }

  input.Checkboxinputclass {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;

    /* creating a custom design */
    width: 20px;
    height: 20px;
    margin: 0;
    border-radius: $borderRadius;
    border: $border;
    outline: none;
    cursor: pointer;
    background-color: $branco;
  }

  input.Checkboxinputclass:checked {
    background-color: $primary;
    position: relative;
  }

  input.Checkboxinputclass:checked::before {
    content: "\2714";
    font-size: 17px;
    color: $secondary;
    position: absolute;
    right: 2px;
    top: -3px;
  }

  .Checkboxlabelclass {
    margin-left: 8px;
    font-size: $fonte12;
    line-height: 16px;
    color: $cinzaTexto;
  }
}
