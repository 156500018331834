@import "src/styles/estilosglobais.scss";

.IncluirEditarTipoEquipamento{
  .Container{
    .IncluirTipoEquipamento{
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 24px;
    }
    .ButtonSalvarContainer{
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 800px) {
    .Container{
      .IncluirTipoEquipamento{
        display: flex;
        flex-direction: column;
      }
    }
    button{
      width: 100%;
    }
  }
}