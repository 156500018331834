@import "src/styles/estilosglobais.scss";

.IconeBtnFarol {
  margin-right: 17px;
  margin-left: -9px;

  &.FiltroIcon {
    width: 19px;
    height: 19px;
  }
}

.Dashboard-PopoverFiltro-box {
  width: 400px;
  background: $branco;
  border: $border;
  border-radius: $borderRadius15 !important;
  overflow: visible!important;

  .Dashboard-PopoverFiltro {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    gap: 16px;
    padding: 18px;

    .RangeDatePicker {
      display: flex;
      gap: 8px;
    }

    .Dashboard-PopoverFiltro-label {
      font-size: $fonte12;
      color: $cinzaTexto;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      border-radius: $borderRadius15;
    }

    .FarolOS-PopoverFiltro-Seletor {
      font-size: $fonte12;
      color: $cinzaTexto;

      .Seletor-Placeholder {
        font-size: $fonte12;
        color: $cinzaTexto;
      }
    }

    .Dashboard-PopoverFiltro-BotaoFiltrar {
      margin-top: 16px;
      height: 53px;
      background-color: $primary;
      border-radius: $borderRadius15;
      font-size: $fonte14;
      font-weight: $regular;
    }

    .Dashboard-PopoverFiltro-BotaoLimpar {
        margin-top: 16px;
        height: 53px;
        border-radius: $borderRadius15;
        font-size: $fonte14;
        font-weight: $regular;
        border: 1px solid $primary;
      }
  }
}

.Seletor-Option {
  font-size: $fonte12;
  color: $cinzaTexto;
}

.Seletor-OptionGroup {
  font-size: $fonte12;
  color: $pretoTexto;
}

@media (max-width: 800px) {
  .Dashboard-PopoverFiltro-box{
    left: 0!important;
    max-width: 100vw!important;
  }
}