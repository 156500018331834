@import "src/styles/estilosglobais.scss";

.FarolOS {
  display: flex;
  flex-direction: column;
  width: 100%;

  hr {
    margin: 16px 0;
  }

  .FarolOS-Topo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .FarolOS-Topo-left {
      padding-left: 21px;

      .Burguer-Menu-Icon {
        width: 24px;
        height: 24px;
        line {
          stroke: $cinza;
        }
      }
    }
  }

  .FarolOS-BlocoInput {
    display: grid;
    grid-template-columns: 64px 1fr;
    height: 61px;
    border: $border;
    border-radius: $borderRadius15;
    margin-top: 23px;
    box-sizing: border-box;
    padding: 5px 10px;

    .InputIcon {
      position: relative;
      top: 17px;
      left: 21px;
    }

    .InputTextBox {
      border: 0;
      outline: 0;
      margin: 0;
      font-size: $fonte20;
      box-sizing: border-box;
      padding-top: 8px;

      &::placeholder {
        color: $cinzaClaro;
        font-size: $fonte20;
      }
    }
  }

  .FarolOS-BlocoBotoes {
    margin-top: 20px;
    height: 46px;
    display: flex;
    justify-content: space-between;

    column-gap: 3.5rem;

    .FarolOS-BlocoBotoes-Link {
      display: flex;
      align-items: center; 
      gap: 16px;
      & .FarolOs-Link {
        cursor: pointer;
        text-decoration: none;
        color: $cinzaTexto;
        font-weight: bold;
      }
    }

    .FarolOS-BlocoBotoes-Left {
      display: flex;
      // width: 21.35%;
      // width: 242px;
      height: 100%;
      background: $branco;
      border: $border;
      border-radius: $borderRadius15;
      overflow: hidden;
      margin-left: 3px;

      .FarolOS-BlocoBotoes-Btn {
        cursor: pointer;
        color: $cinzaTexto;
        display: flex;
        align-items: center;
        padding: 17px;
        // width: 46%;
        border-radius: 13px;
        box-sizing: border-box;

        &.visaoSelecionada {
          color: $secondary;
          background-color: $cinzaClaro;
        }
      }
    }

    .FarolOS-BlocoBotoes-Right {
      display: flex;
      justify-content: space-between;
      height: 100%;
      background: $branco;
      border-radius: $borderRadius15;
        & > * {
          margin-left: 10px;
        }
        .FarolOS-BlocoBotoes-RightBtn {
          cursor: pointer;
          color: $cinzaTexto;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 17px;
          padding-right: 2px;
          // width: 46%;
          width: 132px;
          border: $border;
          border-radius: $borderRadius15;
          box-sizing: border-box;

          // &.filtroSelecionado {
          //   color: $secondary;
          //   background-color: $cinzaClaro;
          // }

          &.BtnOrdenar {
            width: 202px;
          }

          &.BtnSelecionado {
            border: 1px solid $primary;
          }

          .IconeBtnFarol {
            margin-right: 17px;
            margin-left: -9px;

            &.FiltroIcon {
              width: 19px;
              height: 19px;
            }

            &.SortIcon {
              width: 19px;
              height: 13px;
            }
          }

          .FarolOS-BlocoBotoes-RightBtnBtnOrdenarLeft {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .FarolOS-BlocoBotoes-RightBtnBtnOrdenarRight {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 20px;
            padding-left: 12px;
            border-left: $border;
          }
        }
    }
  }

  .NenhumOsList {
    padding-right: 0!important;
  }

  .NoPadding {
    padding: 0px!important;
  }

  .Cabecalho{
    gap: 4px;
  }

  .FarolOS-ListaOS {
    padding-bottom: 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    scrollbar-color: none;

    .NenhumOs {
      display: flex;
      justify-content: center;
      padding: 32px;
      border: $border;
      border-radius: $borderRadius15;
      & > p {
        font-size: $fonte20;
        color: $cinza
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-button:single-button {
      background-color: $cinzaClaro;
      display: none;
      height: 18px;
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: none;
    }

    &::-webkit-scrollbar-track {
      background-color: none;
    }
  }

  .FarolOS-ListaOS-OSsSelecionadas {
    position: fixed;
    width: 450px;
    height: 80px;
    top: 80vh;
    background: $cinza;
    border-radius: 61px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: $fonte12;
    color: $secondary;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &.resumoEquipamentos {
      width: 600px;
    }

    .FarolOS-ListaOS-OSsSelecionadas-left {
      display: flex;
      align-items: center;

      .CloseIcon {
        margin-right: 25px;
        cursor: pointer;
      }
    }

    .FarolOS-ListaOS-OSsSelecionadas-center {
      cursor: pointer;
      display: flex;
      align-items: center;

      .FowardIcon {
        margin-right: 10px;
      }
    }

    .FarolOS-ListaOS-OSsSelecionadas-right {
      cursor: pointer;
      display: flex;
      align-items: center;

      .OpenOSIcon {
        margin-right: 10px;
      }
    }
  }
}

