@import "src/styles/estilosglobais.scss";

.AjusteMassivoOS {
  padding: 0 30px;

  .flexTopoAcoes {
    display: grid;
    grid-template-columns: 1fr 50px;

    .botaoVoltar {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .boxGridSearchFields {
    padding: 50px 40px 20px 40px;
    background-color: #f9f9f9;
    border-radius: 15px;
    margin-bottom: 40px;
  }

  .grid1,
  .grid2,
  .grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }

  .grid2 {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 30px;
  }

  .grid3 {
    grid-template-columns: 1fr 180px;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .TechnicianStock {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .TechnicianStock-Topo {
    display: flex;
    justify-content: space-between;
  }

  .TechnicianStockModal-Paper {
    width: 75%;
  }

  @media (max-width: 900px) {
    .grid1,
    .grid2,
    .grid3 {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }
  }
}


.address-table {
  &-container {
    margin-top: 20px;
    overflow-x: auto;
  }

  width: 100%;
  border-collapse: collapse;
  
  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  input, select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    
    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }
  
  .edit-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
      background-color: #0056b3;
    }
  }
}
