@import "src/styles/estilosglobais.scss";

.ConsultarTipoEquipamento {
  display: flex;
  flex-direction: column;

  .Container{
    .ConsultarEquipamentoPesquisar {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 24px;

      .FiltroSpan {
        font-size: $fonte24;
        color: $cinzaTexto;
        margin-right: 15px;
      }

      .ExportButton{
        display: flex;
        align-items: center;
        gap: 18px; 
        white-space: nowrap
      }

    }
  }

  @media (max-width: 800px) {
    .Container{
      .ConsultarEquipamentoPesquisar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        button{
          margin: 0;
          width: 100%!important;
          justify-content: center;
        }
      }
    }
  }
}