#notification-area svg {
    cursor: pointer;
  }
  
  .notification-item.disabled{
    display: none;
  }
  
  #list-notifications .no-content{
    display: block;
    padding: 15px 5px;
  }
  
  #notification-area .MuiBadge-colorSecondary{
    background: #4fd1a3;
  }