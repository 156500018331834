@import "src/styles/estilosglobais.scss";

.IncluirEditarTratativasContratante {
  .flexTopoAcoes {
    display: grid;
    grid-template-columns: 1fr 50px;

    .botaoVoltar {
      cursor: pointer;
      margin-left: auto;
    }
  }

  .Container {
    margin-top: 20px;

    .IncluirTratativaContratante {
      border-radius: 15px;
      padding: 50px 40px;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;

      .flexCheckbox {
        justify-content: end;

        .Checkboxclass {
          flex-direction: column-reverse;

          input.Checkboxinputclass {
            width: 42px;
            height: 42px;
          }

          input.Checkboxinputclass:checked {
            background-color: $primary;
            position: relative;
          }

          input.Checkboxinputclass:checked::before {
            content: "\2714";
            font-size: 35px;
            color: $secondary;
            position: absolute;
            right: 5px;
            top: -6px;
          }

          .Checkboxlabelclass {
            margin-left: -22px;
            font-weight: 600;
            margin-bottom: 16px;
          }
        }
      }

      & .firstRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr 2fr 150px);
        gap: 20px;
      }

      & .secondRow {
        width: 100%;
        display: flex;
        grid-template-columns: repeat(3, 2fr 1fr);
        gap: 20px;
        align-items: start;
      }

      & .thirdRow {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-end;
        gap: 20px;
      }

      @media only screen and (max-width: 1000px) {
        .grid {
          grid-template-columns: 1fr;
          gap: 16px;
        }
      }
    }

    .flexBottomButtons {
      display: flex;
      justify-content: end;
    }
  }
}
