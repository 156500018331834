@import "src/styles/estilosglobais.scss";

.ValidarResetSenha {
  width: 100%;
  display: flex;
  flex-direction: column;

  .ValidarResetSenhaBox {
    margin: auto;
    padding: 50px 158px;
    box-sizing: border-box;
    width: 784px;

    .ValidarResetSenhaBoxWrapper {
      width: 100%;
    }

    .ValidarResetSenhaBox-texto {
      font-family: $fonteFamilyTitulo;
      font-style: normal;
      font-weight: $bold;
      font-size: $fonte36;
      margin-top: 18px;
      margin-right: 1px;
      text-align: start;
      color: $primary;
    }

    .validar-reset-senha-form-group-texto {
      font-size: $fonte14;
      line-height: 16px;
      color: #797d8c;
      margin: 15px 0;
    }
  }
}

@media (max-width: 800px) {
  .ValidarResetSenha {
    max-width: 100vw;
    .ValidarResetSenhaBox {
      padding: 24px 12px;
      max-width: 100vw;
    }
  }
}
