@import "src/styles/estilosglobais.scss";

#IncluirUsuario {
  /**Remove setas de input number*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .IncluirUsuario-Container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .InsideGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  .InsideGrid4 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    margin-bottom: 20px;
    align-items: baseline;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  .button-list-right {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    @media only screen and (max-width: 1000px) {
      button{
        width: 100%;
      }
    }
  }
}
