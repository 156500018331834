@import "src/styles/estilosglobais.scss";

#ConsultaServico {
  .pagination-containerwrapper {
    margin-bottom: 20px;
  }
  
  .MuiFormControl-root {
    margin-top: 0 !important;
  }

  #TabelaResultadoConsultaServico {
    @media only screen and (max-width: 1000px) {
      span {
        font-size: 0.775rem;
      }
    }
  }
}
