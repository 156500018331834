@import "src/styles/estilosglobais.scss";

.pagination-containerwrapper {
  padding: 0 2px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .pagination-container {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .pagination-item {
      min-width: 26px;
      height: 26px;
      background: $branco;
      border: $border;
      border-radius: $borderRadius;
      padding: 0 3px;
      margin: auto 15px auto 0;
      color: $cinzaTexto;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      letter-spacing: 0.01071em;
      line-height: 1.43;
      font-size: $fonte12;

      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }

      &.selected {
        background-color: $cinzaClaro;
      }

      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Remova essa linha do comentário para baixar os ícones conforme solicitado nos comentários*/
          content: "";
          /* Usando uma escala em, as setas terão o tamanho acompanhando a fonte */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid $cinza;
          border-top: 0.12em solid $cinza;
        }

        &.left {
          transform: rotate(-135deg) translate(-50%);
        }

        &.right {
          transform: rotate(45deg);
        }
      }

      &.disabled {
        pointer-events: none;

        .arrow::before {
          border-right: 0.12em solid $cinza50;
          border-top: 0.12em solid $cinza50;
        }

        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

  .pagination-containerQtdByPage {
    display: flex;
    align-items: center;
    font-size: $fonte12;
    color: $cinzaTexto;

    .qtdPageselectDiv {
      display: flex;
      align-items: center;
      min-width: 40px;
      height: 26px;
      background: $branco;
      border: $border;
      border-radius: $borderRadius;
      margin: auto 5px;
      position: relative;

      .qtdPageSelectBox {
        font-size: $fonte12;
        color: $cinzaTexto;
        cursor: pointer;
        text-indent: 1px;
        text-overflow: "";
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 0 17px 0 5px;
      }

      .qtdPageSelectBox-ExpandIcon {
        width: 10px;
        height: 10px;
        position: absolute;
        right: 5px;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .pagination-containerwrapper {
    flex-wrap: wrap;
    .pagination-item {
      min-width: 25px !important;
    }
    .pagination-containerQtdByPage {
      margin-left: auto !important;
    }
    .qtdPageselectDiv {
      margin: 5px 5px !important;
    }
  }
}
