@import "src/styles/estilosglobais.scss";

.Home {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  background: $branco;

  .Ambiente {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 20px;
    color: $primary;
  }

  .Home-VisaoInicial {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 24px 35px 24px 31px;
    max-width: 100%;

    @media only screen and (max-width: 420px) {
      padding: 24px 30px 24px 26px;
    }
    @media only screen and (max-width: 300px) {
      padding: 24px 10px 24px 10px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 1439px) {
  .Home {
    min-width: 100vw;

    .Home-VisaoInicial {
      justify-content: flex-start;
    }
  }
}
