@import "src/styles/estilosglobais.scss";

.ConsultarChecklist {
  display: flex;
  flex-direction: column;
  width: 100%;

  .Container {
    .FormFiltro {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-bottom: 24px;

    }

    .IncluirEditarModelo {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      margin-bottom: 24px;

      .PrimeiraLinha {
        display: grid;
        grid-template-columns: 0.5fr 2fr;
        width: 100%;
        gap: 48px;

        @media (max-width: 800px) {
          display: grid;
          grid-template-columns: 1fr 2fr;
          width: 100%;
          gap: 16px;
        }

      }

      .Linha {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        gap: 16px 48px;
        justify-items: flex-start;

        @media (max-width: 800px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
          gap: 16px;
          justify-items: flex-start;
        }
      }

      .FiltroSpan {
        font-size: $fonte24;
        color: $cinzaTexto;
        margin-right: 15px;
      }

      .ExportButton {
        display: flex;
        align-items: center;
        gap: 18px;
        white-space: nowrap
      }
    }
  }

  @media (max-width: 800px) {
    .Container {
      .FormFiltro {
        display: flex;
        flex-direction: column;
        align-items: start;

        button {
          margin: 0;
          width: 100% !important;
          justify-content: center;
        }
      }
    }
  }

  .Checklist-BlocoBotoes {
    margin-top: 20px;
    height: 46px;
    display: flex;
    justify-content: flex-end;

    column-gap: 3.5rem;

    .Checklist-BlocoBotoes-Link {
      display: flex;
      align-items: center;
      gap: 16px;

      & .Checklist-Link {
        cursor: pointer;
        text-decoration: none;
        color: $cinzaTexto;
        font-weight: bold;
      }
    }

    .Checklist-BlocoBotoes-Left {
      display: flex;
      // width: 21.35%;
      // width: 242px;
      height: 100%;
      background: $branco;
      border: $border;
      border-radius: $borderRadius15;
      overflow: hidden;
      margin-left: 3px;

      .Checklist-BlocoBotoes-Btn {
        cursor: pointer;
        color: $cinzaTexto;
        display: flex;
        align-items: center;
        padding: 17px;
        // width: 46%;
        border-radius: 13px;
        box-sizing: border-box;

        &.visaoSelecionada {
          color: $secondary;
          background-color: $cinzaClaro;
        }
      }
    }

    .Checklist-BlocoBotoes-Right {
      display: flex;
      justify-content: space-between;
      height: 100%;
      background: $branco;
      border-radius: $borderRadius15;

      &>* {
        margin-left: 10px;
      }

      .Checklist-BlocoBotoes-RightBtn {
        cursor: pointer;
        color: $cinzaTexto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 17px;
        padding-right: 2px;
        // width: 46%;
        width: 132px;
        border: $border;
        border-radius: $borderRadius15;
        box-sizing: border-box;

        // &.filtroSelecionado {
        //   color: $secondary;
        //   background-color: $cinzaClaro;
        // }

        &.BtnExportar {
          background-color: #294033;
          color: white;

          > .ExportIcon{
            color: white;
          }
        }

        &.BtnOrdenar {
          width: 202px;
        }

        &.BtnSelecionado {
          border: 1px solid $primary;
        }

        .IconeBtnFarol {
          margin-right: 17px;
          margin-left: -9px;

          &.FiltroIcon {
            width: 19px;
            height: 19px;
          }

          &.SortIcon {
            width: 19px;
            height: 13px;
          }
        }

        .Checklist-BlocoBotoes-RightBtnBtnOrdenarLeft {
          height: 100%;
          display: flex;
          align-items: center;
        }

        .Checklist-BlocoBotoes-RightBtnBtnOrdenarRight {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
          padding-left: 12px;
          border-left: $border;
        }
      }
    }
  }
}