@import "src/styles/estilosglobais.scss";

.ResumoApp {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .TotalLabel{
    font-size: $fonte14;
    color: $cinzaTexto;
    font-weight: $bold;
  }
  
  .totalTitulo{
    font-size: $fonte16;
    color: $cinzaTexto;
    font-weight: $bold;
  }

  .ValorTotal{
    font-size: $fonte16;
    color: $cinzaTexto;
    font-weight: $bold;
  }

  .ChartContainer{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .DatePickerContainer{
      width: 50%;
      display: flex;
      gap: 16px;
      margin-left: 75px;
      align-items: center
    }
  }

  .AderenciaFiltrosContainer{
    display: grid;
    grid-template-columns: repeat(2,1fr) 2fr 1fr; 
    gap: 16px;
    align-items: flex-end;

    .DatePickerContainer{
      display: flex;
      gap: 16px;
    }

    button{
      margin: 0;
      max-height: 44px;
    }
  }
  .PrestadorRow{
    background-color: $cinzaMtoClaro,
  }

  .PrestadorName{
    font-weight: $bold;
  }
}

