@import "src/styles/estilosglobais.scss";

.MetasProdutividade{
  display: flex;
  flex-direction: column;

  & .MuiTextField-root {
    background-color: $branco;
  }
  

  & > form{
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    & > div{
      & > p.Title {
        font-size: $fonte17;
        color: $cinzaTexto;
        font-weight: $bold;
      }
    }
  }

  .Container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $bgCinzaClaro;
    padding: 60px 43px;
    border-radius: $borderRadius15;

    & > div{
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }

  }
  .ButtonContainer{
    display: flex;
    justify-content: flex-end!important;
    gap: 24px;
  }
}