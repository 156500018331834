@import "src/styles/estilosglobais.scss";

.Login {
  width: 100%;
  display: flex;
  flex-direction: column;

  .LoginBox {
    margin: auto;
    padding: 50px 158px;
    box-sizing: border-box;
    width: 784px;
    // background: $branco;
    // border-radius: $borderRadius;

    .LoginBoxWrapper {
      width: 100%;
    }

    .LoginBox-texto {
      font-family: $fonteFamilyTitulo;
      font-style: normal;
      font-weight: $bold;
      font-size: $fonte36;
      margin-top: 18px;
      margin-right: 1px;
      text-align: start;
      color: $primary;
    }

    .login-form-group {
      margin-top: 20px;

      &:first-of-type {
        margin-bottom: 30px;
      }

      .login-form-group-texto {
        font-size: $fonte14;
        line-height: 16px;
        color: #797d8c;
        margin-bottom: 12px;
      }

      .form-field {
        display: inline-block;
        padding: 4px 10px;
        box-sizing: border-box;
        width: 100%;
        height: 62px;
        border: $border;
        border-radius: $borderRadius;
        font-size: $fonte20;
      }
    }

    .login-form-opcoes {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 12px;
      padding: 0 1px;
    }

    .login-form-link {
      font-size: $fonte12;
    }

    .login-form-cadastro {
      display: flex;
      justify-content: center;

      .login-form-cadastro-texto {
        font-size: $fonte12;
        color: #797d8c;
      }
    }

    .login-form-button {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 5px 10px;
      width: 100%;
      height: 62px;
      background: $primary;
      border-color: $primary;
      border-radius: 5px;
      font-size: 17px;
      line-height: 23px;
      color: #ffffff;
    }
  }

  .form-error {
    display: block;
    color: red;
    font-size: 0.8em;
    font-weight: 500;
  }
}

@media (max-width: 800px) {
  .Login{
    max-width: 100vw;
    .LoginBox{
      padding: 24px 12px;
      max-width: 100vw;
    }
  }
}
