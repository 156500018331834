@import "src/styles/estilosglobais.scss";

.Home-Menu-user {
  display: flex;
  align-items: center;
  padding-right: 5px;

  .Home-Menu-user-NotificationIcon {
    // cursor: pointer;
    margin-right: 23px;
    margin-top: -3px;
  }

  .Home-Menu-user-box {
    // cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px 0 2px 2px;
    box-sizing: border-box;

    @media only screen and (max-width: 420px) {
      flex-direction: column;
      align-items: end;
    }

    .Home-Menu-user-image {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin-right: 12px;
    }

    .Home-Menu-user-box-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 116px;
      margin-right: 5px;
      font-size: $fonte12;
      color: $cinzaTexto;

      .Home-Menu-user-box-nome {
        font-weight: $bold;
      }
    }
  }

  .Home-Menu-user-ExpandIcon {
    width: 13.01px;
    height: 6.11px;
  }

  @media (max-width: 800px) {
    .Home-Menu-user-box-nome{
      text-align: end!important;
    }
    img{
      display: none;
    }
  }
}
