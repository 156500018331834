@import "src/styles/estilosglobais.scss";

.IncluirEditarTecnico{
  .Container{
    .IncluirTecnico{
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-end;
      & .firstRow {
        width: 100%;
        display: grid;
        grid-template-columns: 0.45fr 1fr 1fr;
      }
      & .IncluirTecnicoRow {
        width: 100%;
        display: grid;
        grid-template-columns: 0.45fr 1fr;
      }
      & .secondRow {
        width: 100%;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
      }
      & .thirdRow {
        width: 100%;
        display: grid;
        grid-template-columns: 0.93fr repeat(2, 1fr);
        align-items: flex-end;

      }

      & .PrestadoresAccordionContainer,.ConfiguracaoAccordionContainer, .AtividadesAccordionContainer {
        display: flex;
        border: $border;
        width: 100%;
        border-radius: $borderRadius;
        padding: 16px;
        flex-direction: column;

        & > .AccordionHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          
          & > svg {
            width: 18px;
            height: 18px;
            margin: 0;
          }

          & > span {
            color: $cinzaTexto;
            font-size: $fonte16;
            font-weight: 600;
          }
        }
        
        & .AtividadesRow {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 16px;
          margin-bottom: 16px;

          .BlocoBotoes-RightBtn {
            cursor: pointer;
            color: $cinzaTexto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10.3px 33px;
            border: $border;
            border-radius: $borderRadius15;
            box-sizing: border-box;

            &.BtnExportar {
              background-color: #294033;
              color: white;

              >.ExportIcon {
                margin-right: 4px !important;
                color: white;
              }
            }
          }

          & > button {
            margin: 0;
          }
        }

        & .AtividadesRow:first-of-type {
          align-items: flex-end;
        }

        & > .MuiCollapse-hidden {
          display: none;
        }
        
        
      }

      & .EditarTecnico {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      .EditarTecnicoDadosCriacao{
        display: flex;
        width: 100%;
      }
      & > div {
        gap: 16px;
      }
    }
  }

  @media (max-width: 800px) {
    .Container{
      .IncluirTecnico{
        display: flex;
        flex-direction: column;
      }
    }
  }
}