@import "src/styles/estilosglobais.scss";

.FarolOSFiltroTopo-TotalOSs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .FarolOSFiltroTopo-TotalOSs-Bloco {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
    //width: 262px;
    width: 18.7%;
    height: 132px;
    background: $branco;
    border: 1px solid #afb1b1;
    border-bottom: 8px solid $primary;
    border-radius: 20px;
    overflow: hidden;

    &.filtroSelecionado {
      border: 2px solid $primary;
      border-bottom: 8px solid $primary;
    }
    

    &.borderColorRed {
      border-bottom: 8px solid $osVencida;
      &.filtroSelecionado {
        border: 2px solid $osVencida;
        border-bottom: 8px solid $osVencida;
      }
    }
    &.borderColorOrange {
      border-bottom: 8px solid $osEmergencial;
      &.filtroSelecionado {
        border: 2px solid $osEmergencial;
        border-bottom: 8px solid $osEmergencial;
      }
    }
    &.borderColorYellow {
      border-bottom: 8px solid $osVenceHoje;
      &.filtroSelecionado {
        border: 2px solid $osVenceHoje;
        border-bottom: 8px solid $osVenceHoje;
      }
    }
    &.borderColorGreen {
      border-bottom: 8px solid $osAVencer;
      &.filtroSelecionado {
        border: 2px solid $osAVencer;
        border-bottom: 8px solid $osAVencer;
      }
    }
    

    .FarolOSFiltroTopo-TotalOSs-Bloco-left {
      display: flex;
      flex-direction: column;
      overflow: visible;
      width: 70%;
      max-width: 70%;
      padding: 15px 0 0 5px;
      box-sizing: border-box;

      .FarolOSFiltroTopo-TotalOSs-Bloco-title {
        font-weight: $bold;
        font-size: $fonte13;
        color: $cinzaTexto;
        white-space: nowrap;
        height: 30px;
      }

      .FarolOSFiltroTopo-TotalOSs-Bloco-number {
        font-weight: $extrabold;
        font-size: $fonte32;
        color: $cinzaTexto;
        margin-top: 13px;
      }
    }

    .FarolOSFiltroTopo-TotalOSs-Bloco-right {
      display: flex;
      width: 30%;
      max-width: 30%;
      align-items: flex-end;
      justify-content: flex-end;

      .divPieWrapperr {
        height: 50px;
        width: 50px;
        min-width: 50px;
        box-sizing: border-box;
        margin: 0 10px 20px 0;
      }
    }
  }
}

@media only screen and (max-width: 1270px) {
  .FarolOSFiltroTopo-TotalOSs {
    .FarolOSFiltroTopo-TotalOSs-Bloco {
      .FarolOSFiltroTopo-TotalOSs-Bloco-left {
        .FarolOSFiltroTopo-TotalOSs-Bloco-title {
          font-size: $fonte12;
          white-space: unset;
        }
        .FarolOSFiltroTopo-TotalOSs-Bloco-number {
          font-size: $fonte24;
        }
      }
    }
  }
}

@media only screen and (max-width: 1140px) {
  .FarolOSFiltroTopo-TotalOSs {
    .FarolOSFiltroTopo-TotalOSs-Bloco {
      height: 162px;
    }
  }
}
