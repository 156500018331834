@import "src/styles/estilosglobais.scss";

.TechnicianStock {
  .grid1, .grid2, .grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
  }
  
  .grid2 {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 30px;
  }
  
  .grid3 {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 40px;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .TechnicianStock {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .TechnicianStock-Topo {
    display: flex;
    justify-content: space-between;
  }

  .TechnicianStockModal-Paper {
    width: 75%;
  }

  @media (max-width: 900px) {
    .grid1, .grid2, .grid3 {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }
  }
}
