.ConsultarPrestadorVeiculos-Container {
  display: flex;
  flex-direction: column; 

  .filtro-botoes {
    display: flex;
    justify-content: flex-end; 
    gap: 10px; 
    margin-bottom: 10px;
  }
}
