@import "src/styles/estilosglobais.scss";

#ConsultaParametro {
  .pagination-containerwrapper {
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    margin-top: 0 !important;
  }
  
  .ViewTreeParametro {
    width: '500px';
    min-width: '500px';
    border: '1px solid #e0e0e0';
    border-radius: '8px';
    padding: '16px';
    background-color: '#fff';
    height: 'fit-content';
  }

  .FormParametroContainer {
    flex: 1;
    border: '1px solid #e0e0e0';
    border-radius: '8px';
    padding: '16px';
    background-color: '#fff';
  }

  #TabelaResultadoConsultaParametro {
    @media only screen and (max-width: 1000px) {
      span {
        font-size: 0.775rem;
      }
    }
  }
}