@import "src/styles/estilosglobais.scss";

.ChecklistFiltrosAplicados {
  display: flex;
  flex: 1;
  box-sizing: border-box;
  padding: 12px 0px 2px 0px;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  min-height: 42px;

  .ChecklistFiltrosAplicados-label {
    color: $cinzaTexto;
    color: #797d8c;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .ChecklistFiltrosAplicados-filtro {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 28px;
    flex-shrink: 0;
    border-radius: 13px;
    background: #4fd1a3;
    box-sizing: border-box;
    padding: 2px 5px 2px 15px;
    color: #fff;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &.disableCloseIcon {
      opacity: 0.6;

      .FiltroCloseIcon {
        cursor: default;
      }
    }

    .FiltroCloseIcon {
      cursor: pointer;
    }
  }
}
