@import "src/styles/estilosglobais.scss";

.FarolOS-CardOS {
  width: 100%;
  border: $border;
  border-radius: $borderRadius15;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  .FarolOS-CardOS-Topo {
    display: grid;
    grid-template-columns: 60px 40px 0.6fr 0.7fr 0.7fr 0.7fr 1fr 1fr 2fr 1fr 1fr 0.8fr 60px;
    height: 61px;
    min-height: 61px;
    box-sizing: border-box;
    padding: 5px 10px;

    .FarolOS-CardOS-Collum {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: $fonte16;
      color: $cinzaTexto;
      word-break: break-all;
      line-height: 17px;
      max-height: 51px;

      &.Collumcenter {
        justify-content: center;
      }

      &.CollumBreak-word {
        word-break: break-word;
      }

      span.FarolOS-CardOS-Label {
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        &.LabelBold {
          font-weight: $bold;
          user-select: none;
        }
      }

      .Checkboxclass.CheckboxCardFarolLista {
        margin-left: 13px;

        input.Checkboxinputclass {
          width: 25px;
          height: 25px;
        }

        input.Checkboxinputclass:checked::before {
          font-size: 20px;
          right: 3px;
        }
      }

      .FarolOS-CardOS-StatusIcon {
        margin: auto;

        &.OSEmergencial {
          circle {
            fill: #FF9900;
          }
        }
      }

      .FarolOS-CardOS-ExpandIcon {
        margin: auto;
        cursor: pointer;
        transition: transform 0.3s, padding 0.6s;

        &.cardOpen {
          transform: rotate(180deg);
        }
      }
    }
  }

  .FarolOS-CardOS-Body {
    width: 100%;
    height: 0;
    min-height: 0;
    padding: 0 0;
    transition: height 0.3s, padding 0.6s;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;

    &.cardOpen {
      padding: 15px 17px;
      height: 238px;
    }

    .FarolOS-CardOS-EditIcon {
      margin-left: auto;
      width: 27px;
    }

    .FarolOS-CardOS-Body-Info {
      width: calc(100% - 27px);
      display: flex;

      .FarolOS-CardOS-Body-InfoOS {
        display: flex;
        flex-direction: column;
        max-width: 42%;
        height: 182px;
        border-radius: 15px;
        margin-right: auto;
        margin-left: 14.6%;
        padding: 7px 0 0 21px;
        box-sizing: border-box;

        .FarolOS-CardOS-Body-InfoOS-Item {
          max-width: 100%;
          font-size: $fonte15;
          color: $cinzaTexto;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          span {
            font-weight: $bold;
          }
        }
      }

      .FarolOS-CardOS-Body-Mapa {
        width: 33.5%;
        height: 182px;
        border-radius: $borderRadius15;
        margin-left: auto;
        margin-right: 7.6%;
      }
    }
  }
}

// @media only screen and (max-width: 1439px) {
//   .FarolOS-CardOS {

//   }
// }

// @media only screen and (max-width: 959px) {
//   .FarolOS-CardOS {

//   }
// }
