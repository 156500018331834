@import "src/styles/estilosglobais.scss";

#marker {
  background-image: url("../../../assets/icons/MapLocal.svg");
  background-size: cover;
  width: 35px;
  height: 45px;
}

#selected {
  background-image: url("../../../assets/icons/MapLocalSelected.svg");
  background-size: cover;
  width: 35px;
  height: 45px;
}

.map-container {
  margin-top: 16px;
  height: 500px;
  border-radius: 15px;
}

.popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.mapboxgl-popup-content {
  padding: 16px!important;
  padding-right: 40px!important;
  width: fit-content;
  border-radius: 15px!important;
  box-shadow: 0 10px 20px rgb(0 0 0 / 30%)!important;
}

.popup-chamado {
  font-weight: 600!important;
  font-size: $fonte16;
}

.mapboxgl-popup-close-button {
  font-size: $fonte32;
  right: 8px!important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.popup-content-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  color: $cinza;

  span {
    white-space: nowrap;
    font-size: $fonte16;
  }

  :not(:first-child) {
    height: 40%;
  }

  .popup-content-container-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .farol-status{
      display: flex;
      gap: 8px;

      img {
        width: 24px;
        height: 24px;
      }
      :not(img) {
        position: relative;
        bottom: -3px;
      }
    }
  }
}

.popup-encaminhar-button{
  width: 50%;
  background-color: $primary;
  color: $branco;
  font-size: 14px;
  border: none;
  padding: 18.5px 43px;
  border-radius: 15px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin: 0;
}