@import "src/styles/estilosglobais.scss";

.OsPendente {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  hr {
    margin: 16px 0;
  }

  & .MuiAlert-action {
    margin: unset;
  }
  
  & .pagination-containerwrapper {
    margin-top: auto;
    padding-top: 20px;
  }

  .OsPendente-Topo-left {
    .Burguer-Menu-Icon {
      width: 24px;
      height: 24px;
      line {
        stroke: $cinza;
      }
    }
  }

  .OsPendente-Topo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .OsPendente-Topo-left {
      .Burguer-Menu-Icon {
        width: 24px;
        height: 24px;
        line {
          stroke: $cinza;
        }
      }
    }

    .OsPendenteTitle {
      font-family: $fonteFamilyTitulo;
      font-weight: $extrabold;
      font-size: $fonte32;
      color: $cinzaTexto;
      letter-spacing: 1.2px;
    }
  }

  .OsPendente-Autocomplete {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 5px 0 2px;
    box-sizing: border-box;

    & .MuiInputBase-root {
      border-radius: 15px;
    }
  }

  .OsPendente-ListaOS {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: 18px;
    scrollbar-color: $cinzaClaro #f8f8f8;
    padding-bottom: 8px;

    &.Loading {
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
    }

    .NenhumOs {
      display: flex;
      justify-content: center;
      padding: 32px;
      border: $border;
      border-radius: $borderRadius15;
      & > p {
        font-size: $fonte20;
        color: $cinza
      }
    }

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-button:single-button {
      background-color: $cinzaClaro;
      display: block;
      height: 18px;
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $cinzaClaro;
    }

    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }
  }
}

@import "src/styles/estilosglobais.scss";

.OSPendente-CardOS {
  width: 100%;
  border: $border;
  border-radius: $borderRadius15;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  .OSPendente-CardOS-Topo {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: 61px;
    min-height: 61px;
    box-sizing: border-box;
    padding: 5px 10px;
    // padding: 5px 30px 5px 10px;
    gap: 4px;

    .OSPendente-CardOS-Collum {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: $fonte16;
      color: $cinzaTexto;
      word-break: break-all;
      line-height: 17px;
      max-height: 51px;

      &.Collumcenter {
        justify-content: center;
      }

      &.AlignCenter{
        display: flex;
        text-align: center
      }

      &.CollumBreak-word {
        word-break: break-word;
      }

      span.OSPendente-CardOS-Label {
        margin-right: 5px;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        &.LabelBold {
          font-weight: $bold;
          user-select: none;
        }
      }

      .Checkboxclass.CheckboxCardFarolLista {
        margin-left: 13px;

        input.Checkboxinputclass {
          width: 25px;
          height: 25px;
        }

        input.Checkboxinputclass:checked::before {
          font-size: 20px;
          right: 3px;
        }
      }

      .OSPendente-CardOS-StatusIcon {
        margin: auto;

        &.OSEmergencial {
          circle {
            fill: #FF9900;
          }
        }
      }

      .OSPendente-CardOS-ExpandIcon {
        margin: auto;
        cursor: pointer;
        transition: transform 0.3s, padding 0.6s;

        &.cardOpen {
          transform: rotate(180deg);
        }
      }
    }
  }

  .OSPendente-CardOS-Body {
    width: 100%;
    height: 0;
    min-height: 0;
    padding: 0 0;
    transition: height 0.3s, padding 0.6s;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;

    &.cardOpen {
      padding: 15px 17px;
      height: 238px;
    }

    .OSPendente-CardOS-EditIcon {
      margin-left: auto;
      width: 27px;
    }

    .OSPendente-CardOS-Body-Info {
      width: calc(100% - 27px);
      display: flex;

      .OSPendente-CardOS-Body-InfoOS {
        display: flex;
        flex-direction: column;
        max-width: 42%;
        height: 182px;
        border-radius: 15px;
        margin-right: auto;
        margin-left: 14.6%;
        padding: 7px 0 0 21px;
        box-sizing: border-box;

        .OSPendente-CardOS-Body-InfoOS-Item {
          max-width: 100%;
          font-size: $fonte15;
          color: $cinzaTexto;
          margin-bottom: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          span {
            font-weight: $bold;
          }
        }
      }

      .OSPendente-CardOS-Body-Mapa {
        width: 33.5%;
        height: 182px;
        border-radius: $borderRadius15;
        margin-left: auto;
        margin-right: 7.6%;
      }
    }
  }
}
