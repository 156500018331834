@import "src/styles/estilosglobais.scss";


.Checklist-PopoverFiltro-box {
  width: 866px;
  background: $branco;
  border: $border;
  border-radius: $borderRadius15 !important;
  max-height: 622px;

  .Checklist-PopoverFiltro {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 18px 28px 28px 28px;
    gap: 16px;

    .Checklist-PopoverFiltro-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    .Checklist-PopoverFiltro-Quilo {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
      align-items: center;
      justify-items: center;
      gap: 16px;
    }

    .Checklist-PopoverFiltro-Data {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-items: center;
      gap: 16px;
    }

    .Checklist-PopoverFiltro-label {
      font-size: $fonte12;
      color: $cinzaTexto;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
      border-radius: $borderRadius15;
    }

    .Checklist-PopoverFiltro-Seletor {
      font-size: $fonte12;
      color: $cinzaTexto;

      .Seletor-Placeholder {
        font-size: $fonte12;
        color: $cinzaTexto;
      }
    }

    .Checklist-PopoverFiltro-BotaoFiltrar {
      flex: 1;
      height: 53px;
      background-color: $primary;
      border-radius: $borderRadius15;
      font-size: $fonte14;
      font-weight: $regular;

      &:disabled {
        background-color: $cinzaClaro;
        color: $branco;
      }
    }

    .Checklist-PopoverFiltro-BotaoLimpar {
      flex: 1;
      height: 53px;
      border-radius: $borderRadius15;
      font-size: $fonte14;
      font-weight: $regular;
      border: 1px solid $primary;
      margin-right: 16px;
    }

    .Checklist-PopoverFiltro-Footer {
      display: flex;
      margin-top: 16px;

    }
  }
}

.ChecklistFiltroKMInicialFormik {
  max-width: 150px;
}

.ChecklistFiltroKMFinalFormik {
  max-width: 150px;
}

.Checklist-Label-Quilo {
  font-size: 0.75rem !important;
  font-weight: 600;
  height: 1rem !important;
  color: #797d8c;
}

.Seletor-Option {
  font-size: $fonte12;
  color: $cinzaTexto;
}

.Seletor-OptionGroup {
  font-size: $fonte12;
  color: $pretoTexto;
}

.label-custom {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  height: 1rem !important;
  color: #797d8c;
  margin-bottom: 16px;
}