@import "src/styles/estilosglobais.scss";

#ConsultaTecnico {

  .MuiFormControl-root {
    margin-top: 0 !important;
  }

  #TabelaResultadoConsultaTecnico {
    @media only screen and (max-width: 1000px) {
      span {
        font-size: 0.775rem;
      }
    }
  }
}
